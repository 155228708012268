import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getConversion, startConversion } from '../api/conversions';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Divider,
  Chip,
  Button
} from '@mui/material';
import SeoContent from '../components/SeoContent';
import ConversionForm from '../components/ConversionForm';
import ReactMarkdown from 'react-markdown';

const ConvertPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue, watch, control } = useForm({
    mode: 'onChange'
  });
  
  const [conversion, setConversion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchConversion = async () => {
      try {
        const response = await getConversion(slug);
        setConversion(response.data);
      } catch (err) {
        console.error('Error fetching conversion:', err);
        setError(err.message || 'Failed to load conversion');
      } finally {
        setLoading(false);
      }
    };

    fetchConversion();
  }, [slug]);

  const onSubmit = async (data) => {
    setError(null);
    setSubmitting(true);
    
    try {
      const formData = new FormData();
      
      // Check for required fields before submission
      const missingRequiredFields = [];
      
      Object.entries(data).forEach(([key, value]) => {
        const fieldConfig = conversion.schema.inputs.find(f => f.variable_name === key);
        
        if (!fieldConfig) return; // Skip if field config not found
        
        if (fieldConfig.required) {
          // Check if required field is empty
          if (value === undefined || value === null || value === '') {
            missingRequiredFields.push(fieldConfig.label);
            return;
          }
          
          // Special handling for file fields
          if ((fieldConfig.type === 'file' || fieldConfig.type === 'multi-file') && 
              (!value || (Array.isArray(value) && value.length === 0))) {
            missingRequiredFields.push(fieldConfig.label);
            return;
          }
        }
        
        if (value instanceof FileList || Array.isArray(value)) {
          // Handle multiple files
          if (value.length > 0) {
            // For multi-file fields, append each file with the same key
            Array.from(value).forEach((file, i) => {
              formData.append(key, file);
            });
          }
        } else if (value instanceof File) {
          // Handle single file
          formData.append(key, value);
        } else if (fieldConfig?.type === 'json') {
          // Handle JSON data
          try {
            const jsonValue = typeof value === 'string' ? JSON.parse(value) : value;
            formData.append(key, JSON.stringify(jsonValue));
          } catch (e) {
            throw new Error(`Invalid JSON format for ${fieldConfig.label}`);
          }
        } else {
          // Handle other data types
          formData.append(key, value);
        }
      });
      
      // If any required fields are missing, throw an error
      if (missingRequiredFields.length > 0) {
        throw new Error(`Missing required fields: ${missingRequiredFields.join(', ')}`);
      }

      // Log the FormData contents for debugging (remove in production)
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + (pair[1] instanceof File ? pair[1].name : pair[1]));
      }

      const response = await startConversion(conversion.id, formData);
      if (response.error) {
        setError(response.error);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        navigate(`/result/${response.data.id}`);
      }
    } catch (err) {
      console.error('Conversion error:', err);
      setError(err.message || 'An error occurred during conversion');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f9f9ff"
      >
        <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{conversion ? `${conversion.title} Converter - Free Online Tool` : 'File Converter - Free Online Tool'}</title>
        <meta name="description" content={conversion?.short_description || 'Convert your files online for free with our easy-to-use conversion tool.'} />
        <meta name="keywords" content={conversion ? `${conversion.title}, converter, online, free, ${conversion.tags?.map(tag => tag.tag).join(', ')}` : 'file converter, online converter, free converter'} />
      </Helmet>

      <Box sx={{ my: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {conversion ? (
          <>
            <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: { xs: '2rem', md: '2.5rem' }, color: '#3f51b5', fontWeight: 600 }}>
              {conversion.title} Converter - Free Online Tool
            </Typography>

            {/* Description Section */}
            <Box sx={{ mb: 4, backgroundColor: '#f9f9ff', p: 3, borderRadius: '8px', border: '1px solid #e0e0e0' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                About This Converter
              </Typography>
              <Typography variant="body1" paragraph>
                {conversion.short_description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {conversion.description}
              </Typography>
              
              {/* Tags under description */}
              {conversion.tags && conversion.tags.length > 0 && conversion.tags.some(tag => tag.tag && tag.tag.trim() !== '') && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                  <Typography variant="body2" sx={{ color: '#666', mr: 1, display: 'flex', alignItems: 'center' }}>
                    Tags:
                  </Typography>
                  {conversion.tags
                    .filter(tag => tag.tag && tag.tag.trim() !== '')
                    .map(tag => (
                      <Chip 
                        key={tag.id} 
                        label={tag.tag} 
                        size="small" 
                        color="primary" 
                        variant="outlined"
                        sx={{ borderRadius: '4px' }}
                        onClick={() => navigate(`/tag/${encodeURIComponent(tag.tag)}`)}
                        clickable
                      />
                    ))
                  }
                </Box>
              )}
            </Box>
            
            {/* Conversion Form Section */}
            <Box sx={{ position: 'relative', mb: 4 }}>
              <Box 
                sx={{ 
                  position: 'absolute', 
                  top: '-15px', 
                  left: '20px', 
                  backgroundColor: '#3f51b5', 
                  color: 'white',
                  px: 2,
                  py: 0.5,
                  borderRadius: '4px',
                  fontWeight: 'bold',
                  zIndex: 1
                }}
              >
                {conversion.title}
              </Box>
              
              <ConversionForm 
                conversion={conversion}
                onSubmit={onSubmit}
                loading={submitting}
                error={error}
              />
            </Box>
            
            {/* SEO Content */}
            <SeoContent conversion={conversion} />
            
            {/* Blog Post Section */}
            {conversion.blog_post && (
              <Box sx={{ mt: 4 }}>
                <Divider sx={{ my: 3 }} />
                
                <Typography variant="h5" component="h2" sx={{ mb: 3, fontWeight: 'bold', color: '#3f51b5' }}>
                  About {conversion.title} Conversion
                </Typography>
                
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    backgroundColor: '#f9f9ff', 
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Box className="markdown-wrapper" sx={{
                    '& h1': { fontSize: '1.8rem', fontWeight: 600, color: '#3f51b5', my: 2 },
                    '& h2': { fontSize: '1.5rem', fontWeight: 600, color: '#3f51b5', my: 2 },
                    '& h3': { fontSize: '1.2rem', fontWeight: 600, color: '#3f51b5', my: 1.5 },
                    '& h4': { fontSize: '1.1rem', fontWeight: 600, color: '#3f51b5', my: 1 },
                    '& p': { my: 1.5 },
                    '& ul, & ol': { pl: 3, my: 1.5 },
                    '& li': { mb: 0.5 },
                    '& code': { 
                      backgroundColor: '#f0f0f0', 
                      padding: '2px 4px', 
                      borderRadius: '4px',
                      fontFamily: 'monospace'
                    },
                    '& pre': { 
                      backgroundColor: '#f0f0f0', 
                      padding: '16px', 
                      borderRadius: '4px',
                      overflowX: 'auto',
                      my: 2
                    },
                    '& pre code': { 
                      backgroundColor: 'transparent',
                      padding: 0
                    },
                    '& blockquote': {
                      borderLeft: '4px solid #3f51b5',
                      pl: 2,
                      py: 0.5,
                      my: 2,
                      backgroundColor: '#f5f5f5',
                      borderRadius: '0 4px 4px 0'
                    },
                    '& a': {
                      color: '#3f51b5',
                      textDecoration: 'underline'
                    },
                    '& table': {
                      borderCollapse: 'collapse',
                      width: '100%',
                      my: 2
                    },
                    '& th, & td': {
                      border: '1px solid #ddd',
                      padding: '8px',
                      textAlign: 'left'
                    },
                    '& th': {
                      backgroundColor: '#f2f2f2',
                      fontWeight: 'bold'
                    }
                  }}>
                    <ReactMarkdown>
                      {conversion.blog_post}
                    </ReactMarkdown>
                  </Box>
                </Paper>
              </Box>
            )}
          </>
        ) : (
          <Alert severity="error">
            Conversion not found. Please check the URL and try again.
          </Alert>
        )}
      </Box>

      {/* Footer navigation */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          component={Link} 
          to="/"
          variant="outlined"
          sx={{ 
            borderColor: '#3f51b5',
            color: '#3f51b5',
            '&:hover': {
              borderColor: '#303f9f',
              backgroundColor: 'rgba(63, 81, 181, 0.04)',
            },
          }}
        >
          Back to Home
        </Button>
        
        {conversion?.title && (
          <Button 
            component={Link} 
            to={`/search-results/${encodeURIComponent(conversion.title)}`}
            variant="outlined"
            sx={{ 
              borderColor: '#3f51b5',
              color: '#3f51b5',
              '&:hover': {
                borderColor: '#303f9f',
                backgroundColor: 'rgba(63, 81, 181, 0.04)',
              },
            }}
          >
            Related Conversions
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ConvertPage;
