import api from './axios';

export const getCategories = () => {
  return api.get('/conversions/categories/');
};

export const getCategoriesLight = () => {
  return api.get('/conversions/categories-light/');
};

export const getSubcategories = (categoryName) => {
  return api.get(`/conversions/categories/${encodeURIComponent(categoryName)}/subcategories/`);
};

export const getConversionsForSubcategory = (categoryName, subcategoryName) => {
  // Replace spaces with hyphens for both category and subcategory
  const formattedCategoryName = categoryName.replace(/\s+/g, '-');
  const formattedSubcategoryName = subcategoryName.replace(/\s+/g, '-');
  
  return api.get(`/conversions/categories/${encodeURIComponent(formattedCategoryName)}/subcategories/${encodeURIComponent(formattedSubcategoryName)}/`);
};

export const getConversion = (slug) => {
  return api.get(`/conversions/conversion/${slug}/`);
};

export const getAllTags = () => {
  return api.get('/conversions/tags/');
};

export const getConversionsByTag = (tag) => {
  return api.get(`/conversions/tags/${encodeURIComponent(tag)}/`);
};

export const startConversion = (conversionId, formData) => {
  return api.post(`/execute/${conversionId}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getOperationStatus = async (operationId) => {
  try {
    // Add a timestamp to prevent caching
    const timestamp = new Date().getTime();
    const response = await api.get(`/operations/${operationId}/status/?_=${timestamp}`);
    return response;
  } catch (error) {
    console.error(`Error fetching operation status for ${operationId}:`, error);
    // Add more detailed error information
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request error:', error.message);
    }
    throw error;
  }
};

export const searchLight = (query) => {
  return api.get(`/conversions/search-light/?q=${encodeURIComponent(query)}`);
};

export const searchConversions = (query) => {
  return api.get(`/conversions/search/?q=${encodeURIComponent(query)}`);
};

export const createConversionFromPrompt = (prompt) => {
  return api.post('/create-from-prompt/', { prompt });
};

export const getFilteredConversions = (filters = {}) => {
  const { category, subcategory, tags } = filters;
  
  // Build query parameters
  const params = new URLSearchParams();
  
  if (category) {
    params.append('category', category);
  }
  
  if (subcategory) {
    params.append('subcategory', subcategory);
  }
  
  if (tags && Array.isArray(tags) && tags.length > 0) {
    tags.forEach(tag => params.append('tags', tag));
  }
  
  return api.get(`/conversions/filter/?${params.toString()}`);
};

export const semanticSearch = (query, limit = 10) => {
  return api.get(`/conversions/search/?q=${encodeURIComponent(query)}&limit=${limit}`);
};
