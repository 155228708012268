import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';

/**
 * Renders SEO content sections like "Why Use" and "How to Convert"
 */
const SeoContent = ({ conversion }) => {
  // Helper function to sanitize HTML content if needed
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  // Custom CSS to inject into the HTML content for 2-column layout
  const customStyles = `
    .why-use-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    @media (max-width: 600px) {
      .why-use-section {
        grid-template-columns: 1fr;
      }
    }
    .step-card {
      margin-bottom: 16px;
      padding: 16px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 2px 8px rgba(0,0,0,0.05);
      border: 1px solid #e0e0e0;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }
    .step-card:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    }
    .step-number {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #3f51b5;
      color: white;
      font-weight: bold;
      margin-right: 8px;
    }
    .step-title {
      font-weight: 600;
      color: #3f51b5;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
    }
    .how-to-convert-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  `;

  return (
    <Box sx={{ mt: 8 }}>
      {/* Why Use Section */}
      <Paper elevation={0} sx={{ p: 3, mb: 4, backgroundColor: '#f5f7ff', borderRadius: '8px' }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#3f51b5', fontWeight: 600 }}>
          Why Use Our {conversion.title} Converter
        </Typography>
        
        {conversion.why_use ? (
          <>
            <style>{customStyles}</style>
            <Box 
              className="benefits-container"
              dangerouslySetInnerHTML={createMarkup(conversion.why_use)} 
              sx={{ 
                '& h1': { fontSize: '1.5rem', fontWeight: 600, color: '#3f51b5', my: 2, gridColumn: '1 / -1' },
                '& h2': { fontSize: '1.3rem', fontWeight: 600, color: '#3f51b5', my: 1.5, gridColumn: '1 / -1' },
                '& h3': { fontSize: '1.1rem', fontWeight: 600, color: '#3f51b5', mt: 2, mb: 1 },
                '& h4': { fontSize: '1rem', fontWeight: 600, color: '#3f51b5', my: 1 },
                '& p': { mb: 1.5, lineHeight: 1.6 },
                '& ul, & ol': { pl: 3, mb: 2 },
                '& li': { mb: 0.5, lineHeight: 1.6 },
                '& a': { color: '#3f51b5', textDecoration: 'underline' },
                '& strong, & b': { fontWeight: 600 },
                '& em, & i': { fontStyle: 'italic' },
                '& code': { 
                  backgroundColor: '#f0f0f0', 
                  padding: '2px 4px', 
                  borderRadius: '4px',
                  fontFamily: 'monospace'
                },
                '& pre': { 
                  backgroundColor: '#f0f0f0', 
                  padding: '16px', 
                  borderRadius: '4px',
                  overflowX: 'auto',
                  my: 2
                },
                '& blockquote': {
                  borderLeft: '4px solid #3f51b5',
                  pl: 2,
                  py: 0.5,
                  my: 2,
                  backgroundColor: '#f5f5f5',
                  borderRadius: '0 4px 4px 0'
                },
                '& .benefit': {
                  mb: 0,
                  height: '100%',
                  p: 2,
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  border: '1px solid #e0e0e0',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                },
                '& .why-use-section': {
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: '1fr',
                    sm: 'repeat(2, 1fr)'
                  },
                  gap: 2
                },
                '& div.benefit h3': {
                  fontSize: '1.1rem', 
                  fontWeight: 600, 
                  color: '#3f51b5',
                  mt: 0,
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: '#3f51b5',
                    mr: 1
                  }
                },
                '& div.benefit p': {
                  mb: 0,
                  lineHeight: 1.6
                }
              }}
            />
          </>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2 
          }}>
            <Typography variant="body1" paragraph>
              Our {conversion.title} converter is a free, fast, and secure tool that allows you to convert your files without any hassle.
            </Typography>
            <Typography variant="body1" paragraph>
              Here are some key benefits of using our converter:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={0} sx={{ 
                  p: 2, 
                  backgroundColor: 'white', 
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  height: '100%',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                }}>
                  <Typography variant="body1" sx={{ 
                    fontWeight: 600, 
                    color: '#3f51b5', 
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: '#3f51b5',
                      mr: 1
                    }
                  }}>
                    Free and Unlimited
                  </Typography>
                  <Typography variant="body2">
                    Convert as many files as you need without any cost.
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper elevation={0} sx={{ 
                  p: 2, 
                  backgroundColor: 'white', 
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  height: '100%',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                }}>
                  <Typography variant="body1" sx={{ 
                    fontWeight: 600, 
                    color: '#3f51b5', 
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: '#3f51b5',
                      mr: 1
                    }
                  }}>
                    Secure
                  </Typography>
                  <Typography variant="body2">
                    Your files are processed securely and deleted after conversion.
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper elevation={0} sx={{ 
                  p: 2, 
                  backgroundColor: 'white', 
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  height: '100%',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                }}>
                  <Typography variant="body1" sx={{ 
                    fontWeight: 600, 
                    color: '#3f51b5', 
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: '#3f51b5',
                      mr: 1
                    }
                  }}>
                    Fast
                  </Typography>
                  <Typography variant="body2">
                    Our optimized algorithms ensure quick conversions.
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper elevation={0} sx={{ 
                  p: 2, 
                  backgroundColor: 'white', 
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  height: '100%',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                }}>
                  <Typography variant="body1" sx={{ 
                    fontWeight: 600, 
                    color: '#3f51b5', 
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: '#3f51b5',
                      mr: 1
                    }
                  }}>
                    High Quality
                  </Typography>
                  <Typography variant="body2">
                    We maintain the highest possible quality in the conversion process.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>

      {/* How to Convert Section */}
      <Paper elevation={0} sx={{ p: 3, backgroundColor: '#f5f7ff', borderRadius: '8px' }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#3f51b5', fontWeight: 600 }}>
          How to Convert {conversion.title}
        </Typography>
        
        {conversion.how_to_convert ? (
          <>
            <style>{customStyles}</style>
            <Box 
              className="how-to-container"
              dangerouslySetInnerHTML={createMarkup(conversion.how_to_convert)} 
              sx={{ 
                '& h1': { fontSize: '1.5rem', fontWeight: 600, color: '#3f51b5', my: 2 },
                '& h2': { fontSize: '1.3rem', fontWeight: 600, color: '#3f51b5', my: 1.5 },
                '& h3': { fontSize: '1.1rem', fontWeight: 600, color: '#3f51b5', mt: 2, mb: 1 },
                '& h4': { fontSize: '1rem', fontWeight: 600, color: '#3f51b5', my: 1 },
                '& p': { mb: 1.5, lineHeight: 1.6 },
                '& ul, & ol': { pl: 3, mb: 2 },
                '& li': { mb: 0.5, lineHeight: 1.6 },
                '& a': { color: '#3f51b5', textDecoration: 'underline' },
                '& strong, & b': { fontWeight: 600 },
                '& em, & i': { fontStyle: 'italic' },
                '& code': { 
                  backgroundColor: '#f0f0f0', 
                  padding: '2px 4px', 
                  borderRadius: '4px',
                  fontFamily: 'monospace'
                },
                '& pre': { 
                  backgroundColor: '#f0f0f0', 
                  padding: '16px', 
                  borderRadius: '4px',
                  overflowX: 'auto',
                  my: 2
                },
                '& blockquote': {
                  borderLeft: '4px solid #3f51b5',
                  pl: 2,
                  py: 0.5,
                  my: 2,
                  backgroundColor: '#f5f5f5',
                  borderRadius: '0 4px 4px 0'
                },
                '& .steps': {
                  pl: 0,
                  mb: 2,
                  listStyle: 'none'
                },
                '& .how-to-convert-section': {
                  width: '100%'
                },
                '& .intro': {
                  mb: 2
                },
                '& .tips': {
                  fontStyle: 'italic',
                  mt: 2,
                  color: '#555'
                },
                '& ol.steps': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  pl: 0
                },
                '& ol.steps li': {
                  mb: 0,
                  listStyle: 'none'
                },
                '& ol.steps li .step-card': {
                  height: '100%',
                  p: 2,
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  border: '1px solid #e0e0e0',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                },
                '& ol.steps li strong': {
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  color: '#3f51b5',
                  mb: 1
                }
              }}
            />
          </>
        ) : (
          <Box>
            <Typography variant="body1" paragraph>
              Converting your {conversion.title.toLowerCase()} files is simple with our easy-to-use tool:
            </Typography>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 2
            }} className="how-to-convert-list">
              <Box sx={{ 
                p: 2, 
                backgroundColor: 'white', 
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }} className="step-card">
                <Typography variant="body1" sx={{ 
                  fontWeight: 600, 
                  color: '#3f51b5', 
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center'
                }} className="step-title">
                  <span className="step-number">1</span>
                  Upload your file
                </Typography>
                <Typography variant="body2">
                  Click the upload button or drag and drop your file into the designated area. Our tool supports various file formats.
                </Typography>
              </Box>
              
              <Box sx={{ 
                p: 2, 
                backgroundColor: 'white', 
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }} className="step-card">
                <Typography variant="body1" sx={{ 
                  fontWeight: 600, 
                  color: '#3f51b5', 
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center'
                }} className="step-title">
                  <span className="step-number">2</span>
                  Configure options
                </Typography>
                <Typography variant="body2">
                  Adjust any conversion settings if needed. Our tool provides various options to customize your conversion.
                </Typography>
              </Box>
              
              <Box sx={{ 
                p: 2, 
                backgroundColor: 'white', 
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }} className="step-card">
                <Typography variant="body1" sx={{ 
                  fontWeight: 600, 
                  color: '#3f51b5', 
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center'
                }} className="step-title">
                  <span className="step-number">3</span>
                  Convert
                </Typography>
                <Typography variant="body2">
                  Click the convert button and wait for the process to complete. Our system will process your file quickly and efficiently.
                </Typography>
              </Box>
              
              <Box sx={{ 
                p: 2, 
                backgroundColor: 'white', 
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }} className="step-card">
                <Typography variant="body1" sx={{ 
                  fontWeight: 600, 
                  color: '#3f51b5', 
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center'
                }} className="step-title">
                  <span className="step-number">4</span>
                  Download
                </Typography>
                <Typography variant="body2">
                  Once conversion is done, download your converted file. You can also share the result directly or save it to your cloud storage.
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic', color: '#555' }}>
              That's it! The entire process takes just a few seconds depending on the file size.
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default SeoContent;
