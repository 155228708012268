import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Slider,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useRef } from 'react';

// Common input styling
const inputStyling = {
  backgroundColor: '#f5f7ff',
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#3f51b5',
    },
    '&:hover fieldset': {
      borderColor: '#303f9f',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#303f9f',
    },
  }
};

/**
 * Renders a form field based on its type and configuration
 */
const FormField = ({ 
  field, 
  control, 
  errors, 
  register, 
  createValidationRules,
  setValue,
  watch
}) => {
  const fileInputRef = useRef(null);

  const renderField = () => {
    switch (field.type) {
      case 'select':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl fullWidth error={!!errors[field.variable_name]}>
              <Controller
                name={field.variable_name}
                control={control}
                defaultValue=""
                rules={createValidationRules(field)}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    sx={inputStyling}
                  >
                    <MenuItem value="" disabled>
                      <em>Select an option</em>
                    </MenuItem>
                    {field.validation?.oneOf?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors[field.variable_name] && (
                <FormHelperText error sx={{ mt: 1 }}>{errors[field.variable_name]?.message}</FormHelperText>
              )}
              {field.validation?.oneOf ? (
                <FormHelperText sx={{ mt: 1 }}>
                  Valid values: {field.validation.oneOf.join(', ')}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
        );

      case 'int':
      case 'float':
      case 'number':
        // Use slider if min and max are both defined
        if (field.validation?.min !== undefined && field.validation?.max !== undefined) {
          // For float fields, calculate an appropriate step size based on the range
          let step;
          if (field.type === 'int') {
            step = 1;
          } else {
            // For float fields, calculate step based on the range
            const range = field.validation.max - field.validation.min;
            if (range <= 0.001) {
              step = 0.0001; // Very small range
            } else if (range <= 0.01) {
              step = 0.001; // Small range
            } else if (range <= 0.1) {
              step = 0.01; // Medium range
            } else if (range <= 1) {
              step = 0.1; // Standard range
            } else if (range <= 10) {
              step = 0.5; // Larger range
            } else if (range <= 100) {
              step = 1; // Much larger range
            } else {
              step = Math.pow(10, Math.floor(Math.log10(range)) - 2); // Dynamic calculation
            }
          }
          return (
            <Box key={field.variable_name} sx={{ 
              mb: 2, 
              p: 2, 
              border: '1px solid #e0e0e0', 
              borderRadius: '8px',
              backgroundColor: '#f5f7ff'
            }}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
              </Typography>
              <Controller
                name={field.variable_name}
                control={control}
                defaultValue={field.validation.min}
                rules={createValidationRules(field)}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Slider
                        value={parseFloat(value) || field.validation.min}
                        onChange={(_, newValue) => onChange(newValue)}
                        min={field.validation.min}
                        max={field.validation.max}
                        step={step}
                        valueLabelDisplay="auto"
                        sx={{ 
                          mx: 2, 
                          color: '#3f51b5',
                          '& .MuiSlider-thumb': {
                            height: 24,
                            width: 24,
                            backgroundColor: '#fff',
                            border: '2px solid currentColor',
                            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                              boxShadow: '0 0 0 8px rgba(63, 81, 181, 0.16)',
                            },
                          }
                        }}
                      />
                      <TextField
                        value={value || ''}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val === '' || (!isNaN(parseFloat(val)) && isFinite(val))) {
                            onChange(val);
                          }
                        }}
                        inputProps={{
                          min: field.validation.min,
                          max: field.validation.max,
                          step: 'any', // Explicitly set step to 'any' to prevent default stepping behavior
                          'aria-labelledby': 'input-slider',
                          style: { width: '100px' }
                        }}
                        size="small"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#3f51b5',
                            },
                            '&:hover fieldset': {
                              borderColor: '#303f9f',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#303f9f',
                            },
                          }
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="caption" color="textSecondary">
                        Range: {field.validation.min} to {field.validation.max}
                      </Typography>
                    </Box>
                  </>
                )}
              />
              {errors[field.variable_name] && (
                <FormHelperText error>{errors[field.variable_name]?.message}</FormHelperText>
              )}
            </Box>
          );
        }
        // Fall back to regular number input if min/max not both defined
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              type="number"
              fullWidth
              error={!!errors[field.variable_name]}
              helperText={errors[field.variable_name]?.message}
              {...register(field.variable_name, createValidationRules(field))}
              inputProps={{
                min: field.validation?.min,
                max: field.validation?.max,
                step: 'any' // Explicitly set step to 'any' to prevent default stepping behavior
              }}
              sx={inputStyling}
            />
          </Box>
        );
      case 'json':
      case 'string':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              fullWidth
              multiline={field.multiline}
              rows={field.multiline ? 4 : 1}
              error={!!errors[field.variable_name]}
              helperText={errors[field.variable_name]?.message}
              placeholder={field.placeholder}
              {...register(field.variable_name, createValidationRules(field))}
              sx={inputStyling}
            />
          </Box>
        );
      case 'date':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              type="date"
              fullWidth
              error={!!errors[field.variable_name]}
              helperText={errors[field.variable_name]?.message}
              {...register(field.variable_name, createValidationRules(field))}
              InputLabelProps={{
                shrink: true,
              }}
              sx={inputStyling}
            />
          </Box>
        );
      case 'datetime':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              type="datetime-local"
              fullWidth
              error={!!errors[field.variable_name]}
              helperText={errors[field.variable_name]?.message}
              {...register(field.variable_name, createValidationRules(field))}
              InputLabelProps={{
                shrink: true,
              }}
              sx={inputStyling}
            />
          </Box>
        );
      case 'file':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <Controller
              name={field.variable_name}
              control={control}
              defaultValue=""
              rules={createValidationRules(field)}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: errors[field.variable_name] 
                        ? '2px dashed #f44336' 
                        : '2px dashed #3f51b5',
                      borderRadius: '8px',
                      p: 3,
                      mb: 2,
                      backgroundColor: '#f5f7ff',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#e8eaff',
                        borderColor: errors[field.variable_name] ? '#d32f2f' : '#303f9f',
                      },
                    }}
                    onClick={() => document.getElementById(`file-input-${field.variable_name}`).click()}
                  >
                    <CloudUploadIcon sx={{ 
                      fontSize: 48, 
                      color: errors[field.variable_name] ? '#f44336' : '#3f51b5', 
                      mb: 1 
                    }} />
                    <Typography variant="body1" align="center" color={errors[field.variable_name] ? 'error' : 'inherit'}>
                      {field.required ? 'Drag & drop a file here, or click to select (required)' : 'Drag & drop a file here, or click to select'}
                    </Typography>
                    {field.validation?.allowedFileTypes && (
                      <Typography 
                        variant="caption" 
                        color={errors[field.variable_name] ? 'error' : 'textSecondary'} 
                        align="center" 
                        sx={{ mt: 1 }}
                      >
                        Allowed file types: {field.validation.allowedFileTypes.join(', ')}
                      </Typography>
                    )}
                    <input
                      id={`file-input-${field.variable_name}`}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        onChange(file || null);
                      }}
                      accept={field.validation?.allowedFileTypes?.map(type => 
                        type.startsWith('.') ? type : `.${type}`
                      ).join(',')}
                    />
                  </Box>
                  
                  {value && (
                    <Box 
                      sx={{ 
                        p: 2, 
                        border: '1px solid #e0e0e0', 
                        borderRadius: '8px',
                        backgroundColor: '#f5f7ff',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="body2">
                        Selected file: {value.name}
                      </Typography>
                      <Button 
                        variant="outlined" 
                        color="error" 
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the file input click
                          onChange(null);
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            />
            {errors[field.variable_name] && (
              <FormHelperText error sx={{ mt: 1 }}>{errors[field.variable_name]?.message}</FormHelperText>
            )}
          </Box>
        );
      case 'multi-file':
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <Controller
              name={field.variable_name}
              control={control}
              defaultValue={[]}
              rules={createValidationRules(field)}
              render={({ field: { onChange, value } }) => {
                // Ensure value is always an array
                const files = Array.isArray(value) ? value : [];
                
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: errors[field.variable_name] 
                          ? '2px dashed #f44336' 
                          : '2px dashed #3f51b5',
                        borderRadius: '8px',
                        p: 3,
                        mb: 2,
                        backgroundColor: '#f5f7ff',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#e8eaff',
                          borderColor: errors[field.variable_name] ? '#d32f2f' : '#303f9f',
                        },
                      }}
                      onClick={() => {
                        const input = document.getElementById(`file-input-${field.variable_name}`);
                        if (input) input.click();
                      }}
                    >
                      <CloudUploadIcon sx={{ 
                        fontSize: 48, 
                        color: errors[field.variable_name] ? '#f44336' : '#3f51b5', 
                        mb: 1 
                      }} />
                      <Typography variant="body1" align="center" color={errors[field.variable_name] ? 'error' : 'inherit'}>
                        {field.required ? 'Drag & drop files here, or click to select (required)' : 'Drag & drop files here, or click to select'}
                      </Typography>
                      {field.validation?.allowedFileTypes && (
                        <Typography 
                          variant="caption" 
                          color={errors[field.variable_name] ? 'error' : 'textSecondary'} 
                          align="center" 
                          sx={{ mt: 1 }}
                        >
                          Allowed file types: {field.validation.allowedFileTypes.join(', ')}
                        </Typography>
                      )}
                      <input
                        id={`file-input-${field.variable_name}`}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          const newFiles = Array.from(e.target.files);
                          if (newFiles.length > 0) {
                            onChange([...files, ...newFiles]);
                          }
                        }}
                        accept={field.validation?.allowedFileTypes?.map(type => 
                          type.startsWith('.') ? type : `.${type}`
                        ).join(',')}
                        ref={fileInputRef}
                      />
                    </Box>
                    
                    {files.length > 0 && (
                      <Box 
                        sx={{ 
                          p: 2, 
                          border: '1px solid #e0e0e0', 
                          borderRadius: '8px',
                          backgroundColor: '#f5f7ff'
                        }}
                      >
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          Selected files ({files.length}):
                        </Typography>
                        {files.map((file, index) => (
                          <Box 
                            key={index} 
                            sx={{ 
                              display: 'flex', 
                              justifyContent: 'space-between', 
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            <Typography variant="body2">
                              {file.name}
                            </Typography>
                            <Button 
                              variant="outlined" 
                              color="error" 
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the file input click
                                const newFiles = [...files];
                                newFiles.splice(index, 1);
                                onChange(newFiles);
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ))}
                        <Button 
                          variant="outlined" 
                          color="error" 
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the file input click
                            onChange([]);
                          }}
                          sx={{ mt: 1 }}
                        >
                          Remove All
                        </Button>
                      </Box>
                    )}
                  </Box>
                );
              }}
            />
            {errors[field.variable_name] && (
              <FormHelperText error sx={{ mt: 1 }}>{errors[field.variable_name]?.message}</FormHelperText>
            )}
          </Box>
        );
      default:
        return (
          <Box key={field.variable_name} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              fullWidth
              error={!!errors[field.variable_name]}
              helperText={errors[field.variable_name]?.message}
              {...register(field.variable_name, createValidationRules(field))}
              sx={inputStyling}
            />
          </Box>
        );
    }
  };

  return renderField();
};

export default FormField;
