import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VerifiedBadge from './VerifiedBadge';

const ConversionCard = ({ conversion }) => {
  const href = `/convert/${conversion.slug}`;
  return (
    <Link to={`/convert/${conversion.slug}`} className="group block h-full">
      <div className="h-full bg-white dark:bg-gray-800 rounded-2xl p-6 
        shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300
        border border-gray-100 dark:border-gray-700 hover:border-blue-100 dark:hover:border-blue-900"
      >
        <div className="flex items-center mb-2">
          <h3 className="text-xl font-bold text-gray-800 dark:text-white 
            group-hover:text-blue-600 dark:group-hover:text-blue-400 
            transition-colors duration-300 flex-grow"
          >
            {conversion.title}
          </h3>
          {conversion.is_verified && <VerifiedBadge />}
        </div>
        
        <p className="text-gray-600 dark:text-gray-400 text-sm leading-relaxed mb-6">
          {conversion.description || conversion.short_description}
        </p>

        <div className="flex items-center justify-between mt-auto">
          <div className="flex items-center space-x-2 text-blue-600 dark:text-blue-400">
            <span className="text-sm font-medium transform group-hover:translate-x-1 transition-transform duration-300">
              Convert Now
            </span>
            <ArrowForwardIcon className="w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-300" />
          </div>
          
          {conversion.similarity && (
            <span className="text-sm text-blue-600 dark:text-blue-400 font-medium">
              {conversion.similarity}% match
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ConversionCard;