import React, { useState, useEffect } from 'react';
import { Chip, Box, Typography, Button } from '@mui/material';
import { getAllTags } from '../api/conversions';
import { useNavigate } from 'react-router-dom';

const TagsCloud = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  
  const MAX_INITIAL_TAGS = 20;

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await getAllTags();
        const data = Array.isArray(response.data) ? response.data : [];
        
        // Filter out tags with 0 conversions and sort by conversion count
        const filteredTags = data
          .filter(tag => tag.conversion_count > 0)
          .sort((a, b) => b.conversion_count - a.conversion_count);
        
        setTags(filteredTags);
      } catch (err) {
        console.error('Error fetching tags:', err);
        setError('Failed to load tags');
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  const handleTagClick = (tag) => {
    navigate(`/tag/${encodeURIComponent(tag.tag)}`);
  };

  if (loading) {
    return <Typography>Loading tags...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (tags.length === 0) {
    return null;
  }

  const visibleTags = showAll ? tags : tags.slice(0, MAX_INITIAL_TAGS);

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Popular Tags
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {visibleTags.map((tag) => (
          <Chip
            key={tag.id}
            label={`${tag.tag} (${tag.conversion_count})`}
            onClick={() => handleTagClick(tag)}
            clickable
            color="primary"
            variant="outlined"
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
      {tags.length > MAX_INITIAL_TAGS && (
        <Button 
          onClick={() => setShowAll(!showAll)} 
          sx={{ mt: 2 }}
          variant="text"
        >
          {showAll ? 'Show Less' : `Show More (${tags.length - MAX_INITIAL_TAGS} more)`}
        </Button>
      )}
    </Box>
  );
};

export default TagsCloud;
