import React, { useRef, useEffect, useState } from 'react';
import MonacoEditor from "@monaco-editor/react";
import { Box, Typography } from '@mui/material';

const detectLanguage = (content, filename = '') => {
  // First try to detect from filename if provided
  if (filename) {
    const ext = filename.split('.').pop().toLowerCase();
    const extensionMap = {
      'md': 'markdown',
      'markdown': 'markdown',
      'xml': 'xml',
      'html': 'html',
      'htm': 'html',
      'css': 'css',
      'scss': 'scss',
      'less': 'less',
      'js': 'javascript',
      'jsx': 'javascript',
      'ts': 'typescript',
      'tsx': 'typescript',
      'py': 'python',
      'sql': 'sql',
      'yaml': 'yaml',
      'yml': 'yaml',
      'json': 'json',
      'sh': 'shell',
      'bash': 'shell',
      'dockerfile': 'dockerfile',
      'rs': 'rust',
      'go': 'go',
      'java': 'java',
      'cpp': 'cpp',
      'c': 'c',
      'cs': 'csharp',
      'php': 'php',
      'rb': 'ruby',
      'swift': 'swift',
      'kt': 'kotlin',
      'r': 'r',
      'scala': 'scala'
    };
    
    if (extensionMap[ext]) {
      return extensionMap[ext];
    }
  }

  // If no filename or extension not recognized, try to detect from content
  try {
    // Try parsing as JSON
    JSON.parse(content);
    return 'json';
  } catch (e) {
    const content_lower = content.toLowerCase();
    const content_upper = content.toUpperCase();

    // Check for Markdown
    const mdPatterns = [
      '# ', '## ', '### ', '- [ ]', '* ', '> ', '[', '](', '**', '__',
      '```', '---', '===', '|---', '<https://'
    ];
    if (mdPatterns.some(pattern => content.includes(pattern))) {
      return 'markdown';
    }

    // Check for XML/HTML
    if (content.trim().startsWith('<?xml') || 
        (content.includes('<') && content.includes('>') && 
         (content.includes('</') || content.includes('/>')))) {
      // Check if it's specifically HTML
      if (content_lower.includes('<!doctype html') || 
          content_lower.includes('<html') || 
          content_lower.includes('<body') || 
          content_lower.includes('<head')) {
        return 'html';
      }
      return 'xml';
    }
    
    // Check for YAML
    const yamlPatterns = ['---\n', 'apiVersion:', 'kind:', 'metadata:', '- name:'];
    if (yamlPatterns.some(pattern => content.includes(pattern))) {
      return 'yaml';
    }
    
    // Check for CSS
    if (content.includes('{') && content.includes('}') && content.includes(':')) {
      const cssKeywords = ['margin', 'padding', 'color', 'background', 'font-', '@media', '@import'];
      if (cssKeywords.some(keyword => content_lower.includes(keyword))) {
        return 'css';
      }
    }
    
    // Check for JavaScript/TypeScript
    const jsPatterns = [
      'function', 'const ', 'let ', 'var ', 'import ', 'export ', 'class ',
      '=>', 'async ', 'await ', 'return ', 'interface ', 'type '
    ];
    if (jsPatterns.some(pattern => content.includes(pattern))) {
      // Check if it's TypeScript
      if (content.includes(': string') || 
          content.includes(': number') || 
          content.includes(': boolean') ||
          content.includes('interface ')) {
        return 'typescript';
      }
      return 'javascript';
    }
    
    // Check for Python
    const pyPatterns = [
      'def ', 'class ', 'import ', 'from ', 'if __name__',
      'print(', 'return ', '@', '    ', 'try:', 'except:'
    ];
    if (pyPatterns.some(pattern => content.includes(pattern))) {
      return 'python';
    }
    
    // Check for SQL
    const sqlPatterns = ['SELECT', 'FROM', 'WHERE', 'INSERT', 'UPDATE', 'DELETE', 'CREATE TABLE'];
    if (sqlPatterns.some(pattern => content_upper.includes(pattern))) {
      return 'sql';
    }

    // Check for Shell Script
    const shellPatterns = ['#!/bin/', 'echo ', 'export ', 'sudo ', 'apt-get', 'yum ', 'chmod '];
    if (shellPatterns.some(pattern => content.includes(pattern))) {
      return 'shell';
    }

    // Check for Dockerfile
    const dockerPatterns = ['FROM ', 'RUN ', 'CMD ', 'ENTRYPOINT ', 'COPY ', 'ADD ', 'ENV '];
    if (dockerPatterns.some(pattern => content.includes(pattern))) {
      return 'dockerfile';
    }
    
    // Default to plaintext
    return 'plaintext';
  }
};

const Editor = ({ value, onChange, error, label, filename }) => {
  const editorRef = useRef(null);
  const [language, setLanguage] = useState('plaintext');
  const [editorTheme, setEditorTheme] = useState(
    document.documentElement.classList.contains('dark') ? 'vs-dark' : 'light'
  );

  useEffect(() => {
    if (value) {
      const detectedLang = detectLanguage(value, filename);
      setLanguage(detectedLang);
    }
  }, [value, filename]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const isDark = document.documentElement.classList.contains('dark');
          setEditorTheme(isDark ? 'vs-dark' : 'light');
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  const handleEditorChange = (value) => {
    onChange(value);
  };

  return (
    <div className="w-full mt-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        {label} {language !== 'plaintext' && `(${language})`}
      </label>
      <div className={`
        rounded-lg overflow-hidden
        ${error 
          ? 'border border-red-500 dark:border-red-400' 
          : 'border border-gray-200 dark:border-gray-700'
        }
      `}>
        <MonacoEditor
          height="300px"
          defaultLanguage={language}
          language={language}
          value={value || ''}
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
          theme={document.documentElement.classList.contains('dark') ? 'vs-dark' : 'light'}
          options={{
            minimap: { enabled: false },
            formatOnPaste: true,
            formatOnType: true,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            fontSize: 14,
            wordWrap: 'on',
            renderWhitespace: 'boundary',
            rulers: [80, 100],
            bracketPairColorization: { enabled: true },
          }}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
          {error}
        </p>
      )}
    </div>
  );
};

export default Editor;
