import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Grid,
  Card,
  CardContent,
  Snackbar,
  Button,
  Chip
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getOperationStatus } from '../api/conversions';
import Editor from '../components/Editor';

const ResultPage = () => {
  const { operationId } = useParams();
  const [operation, setOperation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [pollingCount, setPollingCount] = useState(0);
  const [isPolling, setIsPolling] = useState(true);

  // Define fetchOperation as a useCallback function so it can be reused
  const fetchOperation = useCallback(async () => {
    try {
      console.log(`Fetching operation status for ID: ${operationId}`);
      const response = await getOperationStatus(operationId);
      
      // Check if response exists
      if (!response || !response.data) {
        throw new Error('Empty response from server');
      }
      
      const data = response.data;
      
      // Log the operation data for debugging
      console.log('Operation data:', data);
      
      // Make sure we're using the operation status, not HTTP status
      if (typeof data === 'object' && data !== null) {
        // If we have a title but no slug, create a slug from the title
        if (data.title && !data.slug) {
          data.slug = data.title.toLowerCase().replace(/\s+/g, '-');
        }
        
        setOperation(data);
        
        // Handle operation errors
        if (data.errors && data.errors.length > 0) {
          setErrorMessages(data.errors);
          setOpenSnackbar(true);
        }
        
        return data;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('Error fetching operation:', err);
      const errorMessage = err.response?.data?.error || err.message || 'Unknown error occurred';
      setError(errorMessage);
      setErrorMessages([errorMessage]);
      setOpenSnackbar(true);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [operationId]);

  // Manual refresh function
  const handleRefresh = async () => {
    setLoading(true);
    try {
      await fetchOperation();
    } catch (err) {
      console.error('Manual refresh failed:', err);
    }
  };

  useEffect(() => {
    let pollingTimer = null;
    let initialFetchDone = false;
    let pollingAttempts = 0;
    const maxPollingAttempts = 30; // Maximum number of polling attempts (30 * exponential backoff)
    
    const pollOperation = async () => {
      if (!isPolling) return;
      
      try {
        pollingAttempts++;
        console.log(`Polling attempt ${pollingAttempts}/${maxPollingAttempts}`);
        
        const data = await fetchOperation();
        
        // If this is our first successful fetch, mark it
        if (!initialFetchDone) {
          initialFetchDone = true;
          // Reset polling count after first successful fetch
          setPollingCount(0);
        }
        
        // Continue polling if operation is still in progress and we haven't exceeded max attempts
        if ((data.status === 'RUNNING' || data.status === 'PREPARING' || data.status === 'CREATED') && 
            pollingAttempts < maxPollingAttempts) {
          // Calculate delay with a maximum cap
          const baseDelay = 1000; // 1 second base
          const exponentialFactor = Math.min(Math.pow(1.5, pollingCount), 10); // Cap at 10x
          const delay = baseDelay * exponentialFactor;
          
          console.log(`Next poll in ${delay/1000} seconds`);
          
          // Increment polling count for next iteration
          setPollingCount(prev => prev + 1);
          
          pollingTimer = setTimeout(pollOperation, delay);
        } else {
          // Operation completed or max attempts reached, stop polling
          setIsPolling(false);
          console.log('Polling stopped: operation completed or max attempts reached');
        }
      } catch (err) {
        console.error('Error polling operation:', err);
        
        // If we've never successfully fetched data, keep trying
        if (!initialFetchDone && pollingAttempts < maxPollingAttempts) {
          const retryDelay = 2000; // Fixed retry delay for errors
          console.log(`Error occurred, retrying in ${retryDelay/1000} seconds`);
          pollingTimer = setTimeout(pollOperation, retryDelay);
        } else {
          // Otherwise, stop polling after error
          setIsPolling(false);
          console.log('Polling stopped due to error');
        }
      }
    };

    // Start with an immediate fetch
    console.log('Starting initial fetch');
    fetchOperation()
      .then(data => {
        initialFetchDone = true;
        
        // Only start polling if the operation is still in progress
        if (data.status === 'RUNNING' || data.status === 'PREPARING' || data.status === 'CREATED') {
          console.log('Initial fetch successful, operation in progress, starting polling');
          pollingTimer = setTimeout(pollOperation, 1000);
        } else {
          console.log('Initial fetch successful, operation already completed');
          setIsPolling(false);
        }
      })
      .catch((err) => {
        console.error('Initial fetch failed:', err);
        // If initial fetch fails, start polling anyway
        console.log('Starting polling after failed initial fetch');
        pollingTimer = setTimeout(pollOperation, 2000);
      });

    // Cleanup function
    return () => {
      if (pollingTimer) {
        clearTimeout(pollingTimer);
        console.log('Polling timer cleared on component unmount');
      }
    };
  }, [fetchOperation, isPolling]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f9f9ff"
      >
        <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button 
          variant="contained" 
          startIcon={<RefreshIcon />} 
          onClick={handleRefresh}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Container>
    );
  }

  if (!operation) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
          Could not load operation data. Please try refreshing.
        </Alert>
        <Button 
          variant="contained" 
          startIcon={<RefreshIcon />} 
          onClick={handleRefresh}
          sx={{ mt: 2 }}
        >
          Refresh
        </Button>
      </Container>
    );
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'FINISHED':
        return <CheckCircleIcon sx={{ color: 'success.main', fontSize: 28 }} />;
      case 'FAILED':
      case 'FINISHED_WITH_ERROR':
        return <ErrorIcon sx={{ color: 'error.main', fontSize: 28 }} />;
      case 'RUNNING':
      case 'PREPARING':
      case 'CREATED':
        return <AccessTimeIcon sx={{ color: 'info.main', fontSize: 28 }} />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'FINISHED':
        return 'success';
      case 'FAILED':
      case 'FINISHED_WITH_ERROR':
        return 'error';
      case 'RUNNING':
      case 'PREPARING':
      case 'CREATED':
        return 'info';
      default:
        return 'default';
    }
  };

  const renderOutputValue = (operationId, output, field) => {
    if (output === null || output === undefined) {
      return 'N/A';
    }

    switch (field.type) {
      case 'float':
        const floatVal = parseFloat(output);
        return floatVal === 0 ? '0' : floatVal.toFixed(4);
      case 'int':
        const intVal = parseInt(output, 10);
        return intVal === 0 ? '0' : intVal.toString();
      case 'file': case 'multi-file':
        const fileUrl = `${process.env.REACT_APP_API_URL}/api/operations/${operationId}/${output}`;
        return (
          <Button 
            variant="contained"
            color="primary"
            href={fileUrl}
            download 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{
              backgroundColor: '#3f51b5',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }}
          >
            Download File
          </Button>
        );
      case 'json':
      case 'string':
        return (
          <Editor
            label={field.label}
            value={typeof output === 'string' ? output : JSON.stringify(output, null, 2)}
            onChange={() => {}} // Read-only
            options={{ readOnly: true }}
            filename={field.filename || output.filename || ''} // Try to get filename from field or output
          />
        );
      default:
        return String(output);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>{operation.title || 'Conversion Result'} | Convert Everything</title>
        <meta name="description" content={`View your ${operation.title || 'conversion'} results`} />
        <meta name="keywords" content={`${operation.title}, conversion result, convert everything, online converter`} />
      </Helmet>

      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          mb: 4, 
          backgroundColor: '#f9f9ff', 
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}
      >
        {/* Link back to conversion tool - at the very top */}
        {operation.slug && (
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start' }}>
            <Button 
              component={Link} 
              to={`/convert/${operation.slug}`}
              variant="contained" 
              color="primary"
              sx={{ 
                backgroundColor: '#3f51b5',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
            >
              Back to {operation.title} Converter
            </Button>
          </Box>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1" sx={{ color: '#3f51b5', fontWeight: 600 }}>
            {operation.title || 'Conversion Result'}
          </Typography>
          
          <Chip
            icon={getStatusIcon(operation.status)}
            label={operation.status}
            color={getStatusColor(operation.status)}
            variant="outlined"
            sx={{ fontWeight: 500, fontSize: '1rem', px: 1 }}
          />
        </Box>
        
        {operation.description && (
          <Typography variant="body1" color="textSecondary" paragraph>
            {operation.description}
          </Typography>
        )}
        
        {/* Status message */}
        {operation.status === 'RUNNING' && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Your conversion is in progress. This page will automatically refresh to show the latest status.
          </Alert>
        )}
        {operation.status === 'PREPARING' && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Preparing your conversion. This page will automatically refresh to show the latest status.
          </Alert>
        )}
        {operation.status === 'CREATED' && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Your conversion is being initialized. This page will automatically refresh to show the latest status.
          </Alert>
        )}
        {operation.status === 'FINISHED' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Your conversion has completed successfully!
          </Alert>
        )}
        {operation.status === 'FAILED' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Your conversion failed. Please check the error messages below for more information.
          </Alert>
        )}
        
        {/* Status indicator and refresh button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          {isPolling ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={20} thickness={4} sx={{ mr: 1 }} />
              <Typography variant="body2" color="textSecondary">
                Automatically refreshing...
              </Typography>
            </Box>
          ) : (
            <Button 
              variant="outlined" 
              startIcon={<RefreshIcon />} 
              onClick={handleRefresh}
              size="small"
            >
              Refresh Status
            </Button>
          )}
        </Box>
        
        {/* Show errors if any */}
        {operation.errors && operation.errors.length > 0 && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Errors:</Typography>
            <ul>
              {operation.errors.map((err, index) => (
                <li key={index}>{err}</li>
              ))}
            </ul>
          </Alert>
        )}
      </Paper>

      {/* Results Grid */}
      {operation.status === 'FINISHED' && operation.result && operation?.schema?.outputs && (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4, 
            mb: 4, 
            backgroundColor: '#f9f9ff', 
            borderRadius: '8px',
            border: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#3f51b5', fontWeight: 600, mb: 3 }}>
            Conversion Results
          </Typography>
          
          <Grid container spacing={3}>
            {operation.schema.outputs.map((field) => (
              <Grid item xs={12} md={field.type === 'string' || field.type === 'json' ? 12 : 6} key={field.variable_name}>
                <Card 
                  variant="outlined" 
                  sx={{ 
                    height: '100%', 
                    borderRadius: '8px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom sx={{ color: '#3f51b5' }}>
                      {field.label}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {renderOutputValue(
                        operationId,
                        operation.result[field.variable_name],
                        field
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Show input values */}
      {operation.input && operation?.schema?.inputs && (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4, 
            backgroundColor: '#f9f9ff', 
            borderRadius: '8px',
            border: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#3f51b5', fontWeight: 600, mb: 3 }}>
            Input Parameters
          </Typography>
          
          <Grid container spacing={3}>
            {operation.schema.inputs.map((field) => (
              <Grid item xs={12} sm={6} key={field.variable_name}>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 2, 
                    borderRadius: '8px',
                    backgroundColor: '#f5f7ff',
                  }}
                >
                  <Typography variant="subtitle1" component="h3" sx={{ color: '#3f51b5', fontWeight: 500 }}>
                    {field.label}:
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {field.type === 'file' || field.type === 'multi-file'
                      ? 'File uploaded'
                      : String(operation.input[field.variable_name] || 'N/A')}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Footer navigation */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          component={Link} 
          to="/"
          variant="outlined"
          sx={{ 
            borderColor: '#3f51b5',
            color: '#3f51b5',
            '&:hover': {
              borderColor: '#303f9f',
              backgroundColor: 'rgba(63, 81, 181, 0.04)',
            },
          }}
        >
          Back to Home
        </Button>
        
        {operation.title && (
          <Button 
            component={Link} 
            to={`/search-results/${encodeURIComponent(operation.title)}`}
            variant="outlined"
            sx={{ 
              borderColor: '#3f51b5',
              color: '#3f51b5',
              '&:hover': {
                borderColor: '#303f9f',
                backgroundColor: 'rgba(63, 81, 181, 0.04)',
              },
            }}
          >
            Related Conversions
          </Button>
        )}
      </Box>

      {/* Snackbar for errors */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessages.length > 0 ? errorMessages[0] : 'An error occurred'}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResultPage;
