import React from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const ThemeToggle = () => {
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  React.useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  return (
    <button
      onClick={() => setDarkMode(!darkMode)}
      className="fixed bottom-6 right-6 p-3 rounded-full bg-gray-100 dark:bg-gray-800 
        shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300
        hover:bg-gray-200 dark:hover:bg-gray-700"
      aria-label="Toggle Theme"
    >
      {darkMode ? (
        <LightModeIcon className="w-6 h-6 text-yellow-500" />
      ) : (
        <DarkModeIcon className="w-6 h-6 text-gray-600" />
      )}
    </button>
  );
};

export default ThemeToggle;