import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ConversionCard from '../components/ConversionCard';
import { searchConversions, createConversionFromPrompt, searchLight } from '../api/conversions';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  InputBase,
  IconButton,
  Divider,
  Button,
  Grid
} from '@mui/material';

const SearchResultPage = () => {
  const navigate = useNavigate();
  const { searchTerm } = useParams();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(false);
  
  // Search bar state
  const [query, setQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchTimeoutRef = useRef(null);
  const searchBarRef = useRef(null);

  useEffect(() => {
    // Initialize the search query with the current search term
    if (searchTerm) {
      setQuery(searchTerm);
    }
    
    const fetchResults = async () => {
      setLoading(true);
      try {
        const response = await searchConversions(searchTerm);
        setResults(response.data.results);
      } catch (err) {
        setError('Failed to load search results');
        console.error('Error fetching results:', err);
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm) {
      fetchResults();
    }
  }, [searchTerm]);

  // Handle input change and trigger lightweight search
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    if (value.length >= 2) {
      setSearchLoading(true);
      
      // Debounce the search to avoid too many API calls
      searchTimeoutRef.current = setTimeout(async () => {
        try {
          const response = await searchLight(value);
          setFilteredResults(response.data || []);
          setShowResults(true);
        } catch (err) {
          console.error('Error searching:', err);
        } finally {
          setSearchLoading(false);
        }
      }, 300); // 300ms debounce
    } else {
      setShowResults(false);
      setFilteredResults([]);
    }
  };

  // Handle search form submission for full search
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (query.length >= 2) {
      try {
        // Navigate to search results page with the new query
        navigate(`/search-results/${encodeURIComponent(query)}`);
        setShowResults(false);
      } catch (err) {
        console.error('Error submitting search:', err);
      }
    }
  };

  // Handle clicking outside to close results
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Show dropdown with filtered results
  const renderFilteredResults = () => {
    if (!showResults) return null;
    
    if (searchLoading) {
      return (
        <Paper 
          elevation={3} 
          sx={{ 
            position: 'absolute', 
            width: '100%', 
            mt: 2, 
            p: 4, 
            borderRadius: '8px',
            textAlign: 'center',
            zIndex: 50
          }}
        >
          <CircularProgress size={24} sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="body2" color="textSecondary">
            Loading search results...
          </Typography>
        </Paper>
      );
    }

    if (filteredResults.length === 0) {
      return (
        <Paper 
          elevation={3} 
          sx={{ 
            position: 'absolute', 
            width: '100%', 
            mt: 2, 
            p: 4, 
            borderRadius: '8px',
            textAlign: 'center',
            zIndex: 50
          }}
        >
          <Typography variant="body2" color="textSecondary">
            No results found. Try a different search term.
          </Typography>
        </Paper>
      );
    }

    return (
      <Paper 
        elevation={3} 
        sx={{ 
          position: 'absolute', 
          width: '100%', 
          mt: 2, 
          borderRadius: '8px',
          maxHeight: '384px',
          overflow: 'auto',
          zIndex: 50
        }}
      >
        {filteredResults.map((result) => (
          <Box
            key={result.slug}
            sx={{
              p: 3,
              borderBottom: '1px solid #e0e0e0',
              '&:last-child': {
                borderBottom: 'none'
              },
              '&:hover': {
                backgroundColor: '#f5f7ff',
                cursor: 'pointer'
              }
            }}
            onClick={() => {
              navigate(`/convert/${result.slug}`);
              setShowResults(false);
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500, color: '#3f51b5' }}>
              {result.title}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {result.categoryName}
            </Typography>
          </Box>
        ))}
      </Paper>
    );
  };

  const handleCreateConversion = async () => {
    setCreating(true);
    try {
      const response = await createConversionFromPrompt(searchTerm);
      // Navigate to the new conversion
      navigate(`/convert/${response.conversion.slug}`);
    } catch (err) {
      setError('Failed to create conversion');
      console.error('Error creating conversion:', err);
    } finally {
      setCreating(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Helmet>
          <title>{`Search Results for "${searchTerm}" - Convert Everything`}</title>
          <meta name="description" content={`Search results for ${searchTerm}. Find the perfect conversion tool for your needs.`} />
          <meta name="keywords" content={`${searchTerm}, conversion, converter, online converter, free converter`} />
        </Helmet>

        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            mb: 4, 
            fontWeight: 600, 
            color: '#3f51b5' 
          }}
        >
          Search Results for "{searchTerm}"
        </Typography>

        {/* Search Bar and Create New Conversion Section in a flex layout */}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, mb: 5, alignItems: { xs: 'stretch', md: 'flex-start' } }}>
          {/* Search Bar */}
          <Box ref={searchBarRef} sx={{ position: 'relative', flex: { md: 1 } }}>
            <Paper
              component="form"
              onSubmit={handleSubmit}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                backgroundColor: '#f9f9ff',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                }
              }}
            >
              <IconButton sx={{ p: '10px', color: '#3f51b5' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search for conversions..."
                value={query}
                onChange={handleInputChange}
                inputProps={{ 'aria-label': 'search conversions' }}
              />
              {query && (
                <IconButton 
                  sx={{ p: '10px', color: '#9e9e9e' }} 
                  aria-label="clear"
                  onClick={() => {
                    setQuery('');
                    setShowResults(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Paper>
            
            {renderFilteredResults()}
          </Box>

          {/* Create New Conversion Section */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: '8px',
              border: '1px solid #e0e0e0',
              background: 'linear-gradient(135deg, #f0f4ff 0%, #f5f0ff 100%)',
              flex: { md: 1 }
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 1, fontWeight: 600, color: '#3f51b5' }}>
              Can't find what you're looking for?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, color: '#666666' }}>
              Let us create a new conversion tool for your needs.
            </Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={handleCreateConversion}
              disabled={creating}
              sx={{
                px: 2,
                py: 1,
                background: 'linear-gradient(to right, #3f51b5, #7986cb)',
                color: 'white',
                fontWeight: 500,
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(63, 81, 181, 0.2)',
                '&:hover': {
                  background: 'linear-gradient(to right, #303f9f, #5c6bc0)',
                  boxShadow: '0 4px 12px rgba(63, 81, 181, 0.3)',
                  transform: 'translateY(-2px)'
                },
                transition: 'all 0.3s ease',
                textTransform: 'none'
              }}
            >
              {creating ? 'Creating...' : 'Create New Conversion'}
            </Button>
          </Paper>
        </Box>

        <Typography 
          variant="body1" 
          sx={{ 
            mb: 5, 
            color: '#666666',
            lineHeight: 1.6
          }}
        >
          Browse all conversion tools related to your search. These tools help you convert various file formats quickly and easily.
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
            <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
          </Box>
        ) : error ? (
          <Alert 
            severity="error" 
            variant="outlined"
            sx={{ 
              mb: 4,
              p: 3,
              borderRadius: '8px'
            }}
          >
            {error}
          </Alert>
        ) : results.length === 0 ? (
          <Alert 
            severity="info" 
            variant="outlined"
            sx={{ 
              mb: 4,
              p: 3,
              borderRadius: '8px'
            }}
          >
            No conversions found for "{searchTerm}". Try creating a new conversion using the button above.
          </Alert>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600, color: '#3f51b5' }}>
                Available Conversions
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#666666', fontWeight: 500 }}>
                Found {results.length} result{results.length !== 1 ? 's' : ''}
              </Typography>
            </Box>
            
            <Divider sx={{ mb: 4 }} />
            
            <Grid container spacing={3}>
              {results
                .sort((a, b) => {
                  // Sort verified conversions first
                  if (a.is_verified && !b.is_verified) return -1;
                  if (!a.is_verified && b.is_verified) return 1;
                  return 0;
                })
                .map((conversion) => (
                  <Grid item xs={12} sm={6} md={4} key={conversion.id}>
                    <ConversionCard 
                      conversion={conversion}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </>
        )}
      </Box>

      {/* Footer navigation */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          component={Link} 
          to="/"
          variant="outlined"
          sx={{ 
            borderColor: '#3f51b5',
            color: '#3f51b5',
            '&:hover': {
              borderColor: '#303f9f',
              backgroundColor: 'rgba(63, 81, 181, 0.04)',
            },
          }}
        >
          Back to Home
        </Button>
        
        {searchTerm && (
          <Button 
            component={Link} 
            to={`/convert/${encodeURIComponent(searchTerm.toLowerCase().replace(/\s+/g, '-'))}`}
            variant="outlined"
            sx={{ 
              borderColor: '#3f51b5',
              color: '#3f51b5',
              '&:hover': {
                borderColor: '#303f9f',
                backgroundColor: 'rgba(63, 81, 181, 0.04)',
              },
            }}
          >
            Related Conversions
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default SearchResultPage;
