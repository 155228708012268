import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const baseURL = isDevelopment ? `${process.env.REACT_APP_API_URL}/api` : '/api';

// Function to get CSRF token from cookies
function getCsrfToken() {
  const name = 'csrftoken';
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const api = axios.create({
  baseURL,
  timeout: 60000, // Increase timeout to 60 seconds
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  // Add retry logic
  retry: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000; // time interval between retries (1s, 2s, 3s)
  }
});

// Add request interceptor to add CSRF token
api.interceptors.request.use(config => {
  // Add CSRF token to headers for non-GET requests
  if (config.method !== 'get') {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
  }
  return config;
});

// Add a response interceptor for error handling
api.interceptors.response.use(
  response => response,  // Return the original response without modification
  async error => {
    const originalRequest = error.config;
    
    // If the error has no config or we've already retried, reject
    if (!originalRequest || originalRequest._retry) {
      return Promise.reject(error);
    }
    
    // Check if we should retry
    if (error.response && (error.response.status === 429 || error.response.status >= 500) && originalRequest.retry) {
      // Implement retry logic
      if (typeof originalRequest.retryCount === 'undefined') {
        originalRequest.retryCount = 0;
      }
      
      // Check if we still have retries left
      if (originalRequest.retryCount < originalRequest.retry) {
        originalRequest._retry = true;
        originalRequest.retryCount += 1;
        
        // Create a new promise to handle the retry
        const retryDelay = typeof originalRequest.retryDelay === 'function'
          ? originalRequest.retryDelay(originalRequest.retryCount)
          : 1000;
          
        console.log(`Retrying request (${originalRequest.retryCount}/${originalRequest.retry}) after ${retryDelay}ms`);
        
        return new Promise(resolve => {
          setTimeout(() => resolve(api(originalRequest)), retryDelay);
        });
      }
    }
    
    // If we shouldn't retry or we're out of retries, reject with a formatted error
    const message = error.response?.data?.message || error.response?.data?.detail || 'Something went wrong';
    return Promise.reject(new Error(message));
  }
);

export default api;
