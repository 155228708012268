import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography, Paper } from '@mui/material';

const SubcategoryCard = ({ categoryId, subcategoryId, subcategoryFullName, conversionCount }) => {
  // Use just the subcategory name for the URL (not the full path)
  const href = `/category/${encodeURIComponent(categoryId)}/subcategory/${encodeURIComponent(subcategoryId)}`;
  
  // Display the subcategory name (either the full name or just the ID)
  const displayName = subcategoryFullName || subcategoryId;
  
  return (
    <Box 
      component={Link} 
      to={href} 
      sx={{ 
        display: 'block', 
        height: '100%', 
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none'
        }
      }}
    >
      <Paper
        elevation={2}
        sx={{
          height: '100%',
          p: 3,
          borderRadius: 4,
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'grey.100',
          transition: 'all 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: 6,
            borderColor: 'rgba(63, 81, 181, 0.2)'
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Typography 
            variant="h6" 
            component="h3" 
            sx={{ 
              fontWeight: 'bold', 
              mb: 1.5,
              color: 'text.primary',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: '#3f51b5'
              }
            }}
          >
            {displayName}
          </Typography>

          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary', 
              mb: 2,
              flexGrow: 1
            }}
          >
            {conversionCount} conversion{conversionCount !== 1 ? 's' : ''}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: '#3f51b5',
                '& .MuiSvgIcon-root': {
                  transition: 'transform 0.3s ease',
                  ml: 0.5,
                  fontSize: '1rem'
                },
                '&:hover .MuiSvgIcon-root': {
                  transform: 'translateX(4px)'
                }
              }}
            >
              <Typography 
                variant="body2" 
                sx={{ 
                  fontWeight: 500,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'translateX(4px)'
                  }
                }}
              >
                View Conversions
              </Typography>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SubcategoryCard;
