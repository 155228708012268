import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConversionCard from '../components/ConversionCard';
import { getFilteredConversions } from '../api/conversions';
import { Container, Box, Typography, CircularProgress, Alert, Grid, Button } from '@mui/material';

const SubcategoryPage = () => {
  const { categoryId, subcategoryId } = useParams();
  const [subcategory, setSubcategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubcategory = async () => {
      try {
        // Decode the category and subcategory IDs from the URL
        const decodedCategoryId = decodeURIComponent(categoryId);
        const decodedSubcategoryId = decodeURIComponent(subcategoryId);
        
        // Use the new filtered API to get conversions for this category and subcategory
        const response = await getFilteredConversions({
          category: decodedCategoryId,
          subcategory: decodedSubcategoryId
        });
        
        // Check if we have valid data
        if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
          setSubcategory({
            name: decodedSubcategoryId,
            categoryName: decodedCategoryId,
            conversions: response.data.map(conversion => ({
              ...conversion,
              is_verified: conversion.if_verified // Map if_verified to is_verified for consistency
            }))
          });
        } else {
          setError('No conversions found for this subcategory');
        }
      } catch (err) {
        setError('Failed to load subcategory');
        console.error('Error fetching subcategory:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategory();
  }, [categoryId, subcategoryId]);

  return (
    <Box sx={{ minHeight: '100vh', width: '100%', backgroundColor: '#f9f9ff' }}>
      <Container maxWidth="lg" sx={{ py: 4, px: { xs: 2, sm: 4 } }}>
        {subcategory && (
          <Button 
            component={Link} 
            to={`/category/${encodeURIComponent(subcategory.categoryName)}`} 
            startIcon={<ArrowBackIcon />}
            sx={{ 
              mb: 4, 
              color: '#3f51b5',
              '&:hover': {
                backgroundColor: 'rgba(63, 81, 181, 0.04)',
              }
            }}
          >
            Back to {subcategory.categoryName}
          </Button>
        )}

        <Helmet>
          <title>
            {subcategory 
              ? `${subcategory.name} - Convert Everything`
              : 'Loading...'}
          </title>
          {subcategory && (
            <meta 
              name="description" 
              content={`Free online ${subcategory.name} tools in the ${subcategory.categoryName} category. Convert, transform, and process your files easily.`}
            />
          )}
        </Helmet>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
            <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        ) : subcategory ? (
          <>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                mb: 4, 
                fontWeight: 700, 
                color: '#3f51b5'
              }}
            >
              {subcategory.name}
            </Typography>

            <Grid container spacing={3}>
              {subcategory.conversions.map((conversion) => (
                <Grid item xs={12} sm={6} lg={4} key={conversion.slug}>
                  <ConversionCard 
                    conversion={conversion}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
      </Container>
    </Box>
  );
};

export default SubcategoryPage;
