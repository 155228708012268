import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Box } from '@mui/material';

const CategoryCardLazy = ({ category, conversionCount }) => {
  return (
    <Card 
      className="rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
      sx={{ 
        backgroundColor: 'white',
        '&.MuiCard-root': {
          borderRadius: '12px',
        }
      }}
    >
      <CardContent className="p-6">
        <Link to={`/category/${category}`} className="no-underline">
          <Typography 
            variant="h5" 
            component="h2" 
            className="text-xl font-bold text-gray-800 mb-2 hover:text-blue-600 transition-colors duration-200"
          >
            {category}
          </Typography>
        </Link>
        
        <Box className="flex justify-between items-center mt-4">
          <Typography variant="body2" className="text-gray-600">
            {conversionCount} conversion{conversionCount !== 1 ? 's' : ''}
          </Typography>
          
          <Link 
            to={`/category/${category}`}
            className="inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            View All
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CategoryCardLazy;
