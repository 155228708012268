import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Paper,
  Typography,
  Alert,
  CircularProgress
} from '@mui/material';
import FormField from './FormField';
import { createValidationRules } from '../utils/validationUtils';

/**
 * Reusable conversion form component
 */
const ConversionForm = ({ 
  conversion, 
  onSubmit, 
  loading, 
  error 
}) => {
  const { 
    register, 
    handleSubmit, 
    control, 
    formState: { errors }, 
    setValue, 
    watch 
  } = useForm();

  if (!conversion || !conversion.schema || !conversion.schema.inputs) {
    return (
      <Alert severity="error">
        Schema information is missing. Cannot render the form.
      </Alert>
    );
  }

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3, 
        backgroundColor: '#f9f9ff', 
        borderRadius: '8px',
        border: '1px solid #e0e0e0'
      }}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Input Parameters
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Fill in the required fields to convert your {conversion.title.toLowerCase()}
          </Typography>
        </Box>
        
        {conversion.schema.inputs.map((field) => (
          <FormField
            key={field.variable_name}
            field={field}
            register={register}
            control={control}
            errors={errors}
            createValidationRules={createValidationRules}
            setValue={setValue}
            watch={watch}
          />
        ))}
        
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            sx={{
              px: 5,
              py: 1.5,
              borderRadius: '8px',
              backgroundColor: '#3f51b5',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Converting...
              </>
            ) : (
              'Convert Now'
            )}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default ConversionForm;
