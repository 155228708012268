import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import ConvertPage from './pages/ConvertPage';
import ResultPage from './pages/ResultPage';
import SearchResultPage from './pages/SearchResultPage';
import CategoryPage from './pages/CategoryPage';
import SubcategoryPage from './pages/SubcategoryPage';
import TagPage from './pages/TagPage';
import ReactGA from "react-ga4";
import ThemeToggle from './components/ThemeToggle';
import { AppBar, Toolbar, Container, Typography, Box } from '@mui/material';

const TRACKING_ID = "G-E487PDJZ1K";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convert Everything - Free Online File Converter</title>
        <meta name="description" content="Convert any file format online. Free, fast and secure file conversion tool." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://your-domain.com" />
      </Helmet>

      <AppBar position="static" sx={{ 
        backgroundColor: '#f5f7ff', 
        boxShadow: '0 2px 10px rgba(63, 81, 181, 0.1)',
        mb: 2,
        borderBottom: '2px solid transparent',
        borderImage: 'linear-gradient(to right, #3f51b5, #7986cb, #9fa8da) 1'
      }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between', py: 1.5 }}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Typography 
                  variant="h4" 
                  component="div" 
                  sx={{ 
                    fontWeight: 900,
                    letterSpacing: '-0.5px',
                    color: 'transparent',
                    background: 'linear-gradient(90deg, #3f51b5, #7986cb, #9fa8da)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    mr: 1
                  }}
                >
                  Convert
                </Typography>
                <Typography 
                  variant="h4" 
                  component="div" 
                  sx={{ 
                    fontWeight: 900,
                    letterSpacing: '-0.5px',
                    color: '#2c3e50',
                    position: 'relative'
                  }}
                >
                  Everything
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      bottom: -2,
                      left: 0,
                      width: '100%',
                      height: '2px',
                      background: 'linear-gradient(90deg, #3f51b5, #7986cb, #9fa8da)',
                      borderRadius: '2px'
                    }}
                  />
                </Typography>
              </Box>
            </Link>
          </Toolbar>
        </Container>
      </AppBar>

      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/category/:categoryId" element={<CategoryPage />} />
          <Route path="/category/:categoryId/subcategory/:subcategoryId" element={<SubcategoryPage />} />
          <Route path="/convert/:slug" element={<ConvertPage />} />
          <Route path="/result/:operationId" element={<ResultPage />} />
          <Route path="/search-results/:searchTerm" element={<SearchResultPage />} />
          <Route path="/tag/:tag" element={<TagPage />} />
      </Routes>

      <Box 
        component="footer" 
        sx={{ 
          backgroundColor: '#f5f7ff', 
          borderTop: '2px solid transparent',
          borderImage: 'linear-gradient(to right, #3f51b5, #7986cb, #9fa8da) 1',
          py: 4,
          mt: 'auto',
          position: 'relative'
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="body1" align="center" sx={{ color: '#3f51b5', fontWeight: 500, mb: 1 }}>
            Convert Everything - Free Online File Converter
          </Typography>
          <Typography variant="body2" align="center" sx={{ color: '#666666' }}>
            &copy; {new Date().getFullYear()} Convert Everything. All rights reserved.
          </Typography>
        </Container>
      </Box>
      <ThemeToggle />
    </div>
  );
}

export default App;
