import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ConversionCard from '../components/ConversionCard';
import { getFilteredConversions } from '../api/conversions';

const TagPage = () => {
  const { tag } = useParams();
  const [conversions, setConversions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tagName, setTagName] = useState('');

  useEffect(() => {
    const fetchConversions = async () => {
      try {
        setLoading(true);
        const decodedTag = decodeURIComponent(tag);
        setTagName(decodedTag);
        
        // Use the new filtered API to get conversions for this tag
        const response = await getFilteredConversions({
          tags: [decodedTag]
        });
        
        if (response && response.data && Array.isArray(response.data)) {
          setConversions(response.data.map(conversion => ({
            ...conversion,
            is_verified: conversion.if_verified // Map if_verified to is_verified for consistency
          })));
        } else {
          setConversions([]);
        }
      } catch (err) {
        console.error('Error fetching conversions by tag:', err);
        setError('Failed to load conversions for this tag');
      } finally {
        setLoading(false);
      }
    };

    if (tag) {
      fetchConversions();
    }
  }, [tag]);

  return (
    <div className="min-h-screen w-full bg-white dark:bg-gray-900">
      <div className="py-6 px-8">
        <Helmet>
          <title>{`${tagName} Conversions - Convert Everything`}</title>
          <meta name="description" content={`Browse all ${tagName} conversion tools. Free online converters for ${tagName} files and formats.`} />
          <meta name="keywords" content={`${tagName}, conversion, converter, online converter, free converter`} />
        </Helmet>

        <h1 className="text-3xl font-bold text-gray-800 dark:text-white mb-4">
          {tagName} Conversions
        </h1>

        <p className="text-gray-600 dark:text-gray-400 mb-8">
          Browse all conversion tools related to {tagName}. These tools help you convert various file formats quickly and easily.
        </p>

        {loading ? (
          <div className="flex justify-center my-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 dark:bg-red-900/50 border-l-4 border-red-500 p-6 rounded-lg">
            <p className="text-red-700 dark:text-red-400">{error}</p>
          </div>
        ) : conversions.length === 0 ? (
          <div className="bg-blue-50 dark:bg-blue-900/50 border-l-4 border-blue-500 p-6 rounded-lg">
            <p className="text-blue-700 dark:text-blue-400">No conversions found for this tag.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {conversions.map((conversion) => (
              <ConversionCard 
                key={conversion.id} 
                conversion={conversion}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagPage;
