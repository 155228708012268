import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CategoryCardLazy from '../components/CategoryCardLazy';
import TagsCloud from '../components/TagsCloud';
import SearchBar from '../components/SearchBar';
import { getCategoriesLight } from '../api/conversions';
import { Container, Box, Typography, CircularProgress, Alert, Grid } from '@mui/material';

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesLight();
        setCategories(response.data || []);
      } catch (err) {
        setError('Failed to load categories');
        console.error('Error fetching categories:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <Helmet>
        <title>Convert Everything - Home</title>
        <meta name="description" content="Your universal conversion tool. Convert your files quickly, securely and free of charge." />
        <meta name="keywords" content="file converter, online converter, free converter, document conversion, image converter, audio converter, video converter" />
      </Helmet>
      
      <Box sx={{ minHeight: '100vh', width: '100%', backgroundColor: '#f9f9ff' }}>
        <Container maxWidth="lg" sx={{ py: 6 }}>
          <Typography 
            variant="h2" 
            component="h1" 
            align="center" 
            sx={{ 
              mb: 4, 
              fontWeight: 800, 
              letterSpacing: '-0.5px',
              color: 'transparent',
              background: 'linear-gradient(90deg, #3f51b5, #7986cb, #9fa8da)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              py: 2
            }}
          >
            Convert Everything
          </Typography>

          <Typography 
            variant="h5" 
            align="center" 
            sx={{ 
              mb: 6, 
              maxWidth: '800px', 
              mx: 'auto', 
              color: '#666666',
              lineHeight: 1.5
            }}
          >
            Your universal conversion tool. Convert your files 
            <Box component="span" sx={{ color: '#3f51b5', fontWeight: 600 }}> quickly</Box>, 
            <Box component="span" sx={{ color: '#3f51b5', fontWeight: 600 }}> securely</Box> and 
            <Box component="span" sx={{ color: '#3f51b5', fontWeight: 600 }}> free</Box> of charge.
          </Typography>
  
          {/* Search Bar */}
          <Box sx={{ mb: 8, maxWidth: '800px', mx: 'auto' }}>
            <SearchBar />
          </Box>

          {/* Categories Grid */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
              <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mx: 4 }}>
              {error}
            </Alert>
          ) : (
            <Grid container spacing={3} sx={{ px: 2 }}>
              {categories.map((category) => (
                <Grid item xs={12} sm={6} lg={4} key={category.name}>
                  <CategoryCardLazy
                    category={category.name}
                    conversionCount={category.conversion_count}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          
          {/* Tags Cloud */}
          <Box sx={{ maxWidth: '1200px', mx: 'auto', px: 2, mt: 6 }}>
            <TagsCloud />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HomePage;
