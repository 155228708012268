import React from 'react';
import { Box, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const VerifiedBadge = ({ sx = {} }) => {
  return (
    <Tooltip title="Verified Conversion" arrow>
      <Box 
        component="span" 
        sx={{ 
          display: 'inline-flex',
          alignItems: 'center',
          ml: 1,
          color: 'primary.main',
          ...sx
        }}
      >
        <VerifiedIcon fontSize="small" />
      </Box>
    </Tooltip>
  );
};

export default VerifiedBadge;
