import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SubcategoryCard from '../components/SubcategoryCard';
import { getCategoriesLight, getSubcategories } from '../api/conversions';
import { Container, Box, Typography, CircularProgress, Alert, Grid, Button } from '@mui/material';

const CategoryPage = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        // Decode the category ID from the URL
        const decodedCategoryId = decodeURIComponent(categoryId);
        
        // First, get the lightweight category data to verify the category exists
        const categoriesResponse = await getCategoriesLight();
        
        // Find the category in the lightweight data
        const categoryData = categoriesResponse.data.find(
          (cat) => cat.name.toLowerCase() === decodedCategoryId.toLowerCase()
        );

        if (!categoryData) {
          setError('Category not found');
          setLoading(false);
          return;
        }
        
        // Set the category with the found data
        setCategory({
          name: categoryData.name,
          conversionCount: categoryData.conversion_count
        });
        
        // Now fetch the subcategories for this category
        const subcategoriesResponse = await getSubcategories(decodedCategoryId);
        setSubcategories(subcategoriesResponse.data || []);
        
      } catch (err) {
        setError('Failed to load category');
        console.error('Error fetching category:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, [categoryId]);

  return (
    <Box sx={{ minHeight: '100vh', width: '100%', backgroundColor: '#f9f9ff' }}>
      <Container maxWidth="lg" sx={{ py: 4, px: { xs: 2, sm: 4 } }}>
        <Button 
          component={Link} 
          to="/" 
          startIcon={<ArrowBackIcon />}
          sx={{ 
            mb: 4, 
            color: '#3f51b5',
            '&:hover': {
              backgroundColor: 'rgba(63, 81, 181, 0.04)',
            }
          }}
        >
          Back to Categories
        </Button>

        <Helmet>
          <title>{category ? `${category.name} - Convert Everything` : 'Loading...'}</title>
          {category && (
            <meta 
              name="description" 
              content={`Free online ${category.name} tools. Convert, transform, and process your files with our ${category.name} converters.`}
            />
          )}
        </Helmet>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
            <CircularProgress size={60} thickness={4} sx={{ color: '#3f51b5' }} />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        ) : category ? (
          <>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                mb: 4, 
                fontWeight: 700, 
                color: '#3f51b5'
              }}
            >
              {category.name}
            </Typography>
            
            {subcategories.length === 0 ? (
              <Alert severity="info" sx={{ mb: 4 }}>
                No subcategories found for this category.
              </Alert>
            ) : (
              <Grid container spacing={3}>
                {subcategories.map((subcategory) => {
                  // Extract just the subcategory name from the full path (e.g., "Image Processing > Image Analysis" -> "Image Analysis")
                  const subcategoryName = subcategory.name.includes('>') 
                    ? subcategory.name.split('>').pop().trim() 
                    : subcategory.name;
                  
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={subcategory.name}>
                      <SubcategoryCard
                        categoryId={category.name}
                        subcategoryId={subcategoryName}
                        subcategoryFullName={subcategoryName}
                        conversionCount={subcategory.conversion_count}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        ) : null}
      </Container>
    </Box>
  );
};

export default CategoryPage;
