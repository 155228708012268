/**
 * Creates validation rules for form fields based on their type and validation requirements
 */
export const createValidationRules = (field) => {
  const rules = {};

  if (field.required) {
    if (field.type === 'file' || field.type === 'multi-file') {
      // Special handling for required file fields
      rules.validate = {
        ...(rules.validate || {}),
        required: (value) => {
          // Check if any files are provided
          if (!value) return `${field.label} is required`;
          
          // Handle different file input types
          const fileList = Array.isArray(value) ? value : 
                          value instanceof FileList ? Array.from(value) : 
                          value instanceof File ? [value] : [];
          
          return fileList.length > 0 || `${field.label} is required`;
        }
      };
    } else {
      rules.required = `${field.label} is required`;
    }
  }

  if (field.validation) {
    if (field.type === 'int' || field.type === 'float') {
      if ('min' in field.validation) {
        rules.min = {
          value: field.validation.min,
          message: `Must be at least ${field.validation.min}`
        };
        
        // Add valueAsNumber for float fields
        if (field.type === 'float') {
          rules.valueAsNumber = true;
        }
      }
      if ('max' in field.validation) {
        rules.max = {
          value: field.validation.max,
          message: `Must be at most ${field.validation.max}`
        };
      }
    }

    if (field.type === 'date' || field.type === 'datetime') {
      if ('min' in field.validation) {
        rules.min = {
          value: field.validation.min,
          message: `Date must be after ${new Date(field.validation.min).toLocaleDateString()}`
        };
      }
      if ('max' in field.validation) {
        rules.max = {
          value: field.validation.max,
          message: `Date must be before ${new Date(field.validation.max).toLocaleDateString()}`
        };
      }
    }

    if (field.type === 'select' && field.validation?.oneOf) {
      // Add oneOf validation for select fields
      const validValues = field.validation.oneOf.map(option => option);
      
      // If validation.oneOf exists, use it, otherwise use the options
      if (field.validation?.oneOf) {
        rules.validate = {
          ...(rules.validate || {}),
          oneOf: value => field.validation.oneOf.includes(value) || 
            `Value must be one of: ${field.validation.oneOf.join(', ')}`
        };
      }
    }

    if (field.type === 'string' && field.validation.pattern) {
      rules.pattern = {
        value: new RegExp(field.validation.pattern),
        message: 'Invalid format'
      };
    }

    if (field.type === 'file' || field.type === 'multi-file') {
      rules.validate = {
        ...(rules.validate || {}),
        fileType: (files) => {
          if (!files || !field.validation?.allowedFileTypes) return true;
          
          // Handle different file input types
          const fileList = Array.isArray(files) ? files : 
                          files instanceof FileList ? Array.from(files) : 
                          files instanceof File ? [files] : [];
          
          // If no files or empty array, validation passes if not required
          if (fileList.length === 0) return true;
          
          // Normalize allowed file types (remove leading dots for comparison)
          const allowedExtensions = field.validation.allowedFileTypes.map(type => 
            type.startsWith('.') ? type.substring(1).toLowerCase() : type.toLowerCase()
          );
          
          return fileList.every(file => {
            // Make sure file is a valid File object with a name property
            if (!file || !file.name) return true;
            
            const fileNameParts = file.name.split('.');
            // If no extension, validation passes
            if (fileNameParts.length <= 1) return true;
            
            const ext = fileNameParts.pop().toLowerCase();
            return allowedExtensions.includes(ext);
          }) || `Only ${field.validation.allowedFileTypes.join(', ')} files are allowed`;
        }
      };
    }
  }

  return rules;
};

export default createValidationRules;
