import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { searchLight, searchConversions } from '../api/conversions';

const SearchBar = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchTimeoutRef = useRef(null);
  const searchBarRef = useRef(null);

  // Handle input change and trigger lightweight search
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    if (value.length >= 2) {
      setSearchLoading(true);
      
      // Debounce the search to avoid too many API calls
      searchTimeoutRef.current = setTimeout(async () => {
        try {
          const response = await searchLight(value);
          setFilteredResults(response.data || []);
          setShowResults(true);
        } catch (err) {
          console.error('Error searching:', err);
        } finally {
          setSearchLoading(false);
        }
      }, 300); // 300ms debounce
    } else {
      setShowResults(false);
      setFilteredResults([]);
    }
  };

  // Handle search form submission for full search
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (query.length >= 2) {
      try {
        // Navigate to search results page
        navigate(`/search-results/${encodeURIComponent(query)}`);
        setShowResults(false);
      } catch (err) {
        console.error('Error submitting search:', err);
      }
    }
  };

  // Handle clicking outside to close results
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Show dropdown with filtered results
  const renderFilteredResults = () => {
    if (!showResults) return null;
    
    if (searchLoading) {
      return (
        <div className="absolute w-full bg-white dark:bg-gray-800 mt-2 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 z-50 p-4 text-center">
          <div className="inline-block animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500 mr-2"></div>
          <span className="text-gray-600 dark:text-gray-300">Loading search results...</span>
        </div>
      );
    }

    if (filteredResults.length === 0) {
      return (
        <div className="absolute w-full bg-white dark:bg-gray-800 mt-2 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 z-50 p-4 text-center">
          <span className="text-gray-600 dark:text-gray-300">No results found. Try a different search term.</span>
        </div>
      );
    }

    return (
      <div className="absolute w-full bg-white dark:bg-gray-800 mt-2 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 z-50 max-h-96 overflow-y-auto">
        {filteredResults.map((result) => (
          <div
            key={result.slug}
            className="p-3 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer border-b border-gray-100 dark:border-gray-700 last:border-0"
            onClick={() => {
              navigate(`/convert/${result.slug}`);
              setShowResults(false);
            }}
          >
            <div className="font-medium text-gray-800 dark:text-white">{result.title}</div>
            <div className="text-xs text-gray-500 dark:text-gray-400">{result.categoryName}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={searchBarRef} className="relative w-full max-w-2xl mx-auto">
      <form onSubmit={handleSubmit} className="relative">
        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Search for conversions..."
            className="w-full py-3 pl-12 pr-10 rounded-xl bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-900 text-gray-900 dark:text-white transition-all duration-200 focus:outline-none"
          />
          <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
          
          {query && (
            <button
              type="button"
              onClick={() => {
                setQuery('');
                setShowResults(false);
              }}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </form>
      
      {renderFilteredResults()}
    </div>
  );
};

export default SearchBar;
